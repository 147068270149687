import React, { useState, useRef, useEffect } from 'react';
import { Mic, X, Volume2, VolumeX, Camera, RefreshCw } from 'lucide-react';
import { handleVoiceInteraction, setupCamera } from '../services/voiceopenai';

const VoiceModePopup = ({ 
  isOpen, 
  onClose, 
  darkMode, 
  currentUser, 
  memories, 
  messages, 
  onMessageReceived,
  audioRef
}) => {
  const [isListening, setIsListening] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [facingMode, setFacingMode] = useState('user');
  const videoRef = useRef(null);

  useEffect(() => {
    const closeCamera = () => {
      if (videoRef.current && videoRef.current.srcObject) {
        let tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        videoRef.current.srcObject = null;
      }
    };

    if (cameraOpen) {
      setupCamera(videoRef.current, facingMode);
    } else {
      closeCamera();
    }

    return closeCamera; // Cleanup on unmount
  }, [cameraOpen, facingMode]);

  const handleVoiceInteractionStart = async () => {
    setIsListening(true);
    try {
      if (audioRef.current && audioRef.current.state === 'suspended') {
        await audioRef.current.resume();
      }

      const response = await handleVoiceInteraction(
        currentUser, 
        memories, 
        messages.map(msg => ({ role: msg.sender === 'user' ? 'user' : 'assistant', content: msg.text })),
        audioEnabled,
        videoRef.current,
        audioRef.current
      );

      onMessageReceived(response.text);
      
      if (audioEnabled) {
        setIsSpeaking(true);
        await new Promise(resolve => setTimeout(resolve, 2000));
        setIsSpeaking(false);
      }
    } catch (error) {
      console.error('Error in voice interaction:', error);
    } finally {
      setIsListening(false);
    }
  };

  const toggleAudio = () => {
    setAudioEnabled(!audioEnabled);
  };

  const toggleCamera = () => {
    if (cameraOpen) {
      // Aggressive close camera
      if (videoRef.current && videoRef.current.srcObject) {
        let tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach(track => track.stop());
        videoRef.current.srcObject = null;
      }
      setCameraOpen(false);
    } else {
      setCameraOpen(true);
    }
  };

  const switchCamera = () => {
    setFacingMode(prevMode => prevMode === 'user' ? 'environment' : 'user');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 w-96`}>
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">Voice Mode</h2>
          <button onClick={() => { 
            if (cameraOpen) {
              // Aggressive close camera when closing popup
              if (videoRef.current && videoRef.current.srcObject) {
                let tracks = videoRef.current.srcObject.getTracks();
                tracks.forEach(track => track.stop());
                videoRef.current.srcObject = null;
              }
            }
            setCameraOpen(false); 
            onClose(); 
          }} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>
        <p className="mb-4">Click the microphone to start speaking.</p>
        <div className="flex items-center justify-between mb-4">
          <button
            onClick={handleVoiceInteractionStart}
            disabled={isListening || isSpeaking}
            className={`flex-1 py-3 px-4 rounded-full ${
              isListening || isSpeaking
                ? 'bg-avacasa-600 text-white'
                : 'bg-avacasa-500 text-white hover:bg-avacasa-600'
            } transition-colors flex items-center justify-center mr-2`}
          >
            <Mic size={18} className="mr-2" />
            {isListening ? 'Listening...' : isSpeaking ? 'Speaking...' : 'Start Speaking'}
          </button>
          <button
            onClick={toggleAudio}
            className={`p-2 rounded-full ${
              audioEnabled
                ? 'bg-green-500 text-white'
                : 'bg-red-500 text-white'
            } hover:opacity-80 transition-opacity mr-2`}
          >
            {audioEnabled ? <Volume2 size={18} /> : <VolumeX size={18} />}
          </button>
          <button
            onClick={toggleCamera}
            className={`p-2 rounded-full ${
              cameraOpen
                ? 'bg-red-500 text-white'
                : 'bg-green-500 text-white'
            } hover:opacity-80 transition-opacity`}
          >
            <Camera size={18} />
          </button>
        </div>
        {cameraOpen && (
          <div className="relative mb-4">
            <video ref={videoRef} className="w-full rounded-lg" autoPlay playsInline muted />
            <button
              onClick={switchCamera}
              className="absolute top-2 right-2 p-2 bg-white rounded-full shadow-md"
            >
              <RefreshCw size={18} />
            </button>
          </div>
        )}
        <p className="text-sm text-gray-500">
          {audioEnabled ? 'Audio responses are enabled' : 'Audio responses are disabled'}
        </p>
        <p className="text-sm text-yellow-500 mt-4">
          Disclaimer: This voice mode feature is experimental and may not work as expected.
        </p>
      </div>
    </div>
  );
};

export default VoiceModePopup;
