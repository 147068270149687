import React, { useState, useCallback, useRef, useEffect } from 'react';
import { BarChart2, Shirt, Compass, Lightbulb, Send, Image, Menu, User, Moon, Sun, X, PlusCircle, Search, ChevronLeft, LogOut, Droplet, Mic } from 'lucide-react';
import { generateResponse, processMemories } from '../services/openai';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import UserProfile from './UserProfile';
import { useAuth } from '../contexts/AuthContext';
import { db, storage } from '../firebase';
import { collection, addDoc, query, orderBy, onSnapshot, doc, updateDoc, deleteDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { saveMemory, getMemories, updateMemory, deleteMemory, deleteAllMemories } from '../services/memoryService';
import ManageMemories from './ManageMemories';
import ColorAnalysis from './ColorAnalysis';
import SkinAnalysis from './SkinAnalysis';
import Wardrobe from './Wardrobe';
import RateMyLook from './RateMyLook';
import RoastMyLook from './RoastMyLook';
import StyleTransformer from './StyleTransformer';
import VersusMode from './VersusMode';
import StyleHoroscope from './StyleHoroscope';
import StyleQuiz from './StyleQuiz';
import TravelPacking from './TravelPacking';
import SkincareRoutineBuilder from './SkincareRoutineBuilder';
import { startSpeechRecognition, synthesizeSpeech } from '../services/azureSpeechService';
import VoiceModePopup from './VoiceModePopup';
import BeautyAnalysis from './BeautyAnalysis';
import HairAnalysis from './HairAnalysis';




const FeatureButton = ({ icon: Icon, label, isActive, onClick }) => {
    const { darkMode } = useAuth();
    return (
      <button 
        className={`flex items-center justify-center space-x-2 py-2 px-4 rounded-full transition-all duration-300 ${
          isActive 
            ? darkMode 
              ? 'bg-avacasa-600 text-white' 
              : 'bg-avacasa-500 text-white'
            : darkMode 
              ? 'text-gray-300 hover:bg-gray-800' 
              : 'text-gray-600 hover:bg-gray-100'
        }`}
        onClick={onClick}
      >
        <Icon size={18} />
        <span className="text-sm font-medium hidden md:inline">{label}</span>
      </button>
    );
  };


const MobileFeatureButton = ({ icon: Icon, label, isActive, onClick }) => {
  const { darkMode } = useAuth();
  return (
    <button 
      className={`flex flex-col items-center justify-center space-y-1 py-2 px-3 rounded-full transition-all duration-300 ${
        isActive 
          ? darkMode ? 'bg-avacasa-600 text-white' : 'bg-avacasa-500 text-white'
          : darkMode ? 'text-gray-300 hover:bg-gray-800' : 'text-gray-600 hover:bg-gray-100'
      }`}
      onClick={onClick}
    >
      <Icon size={18} />
      <span className="text-xs font-medium">{label}</span>
    </button>
  );
};

const Message = ({ text, sender, images, userProfileImage, isProcessing, wardrobeItems }) => {
  const { darkMode } = useAuth();
  const [showWardrobeModal, setShowWardrobeModal] = useState(false);
  const [selectedWardrobeItem, setSelectedWardrobeItem] = useState(null);

  const renderText = (textContent) => {
    if (!Array.isArray(wardrobeItems) || wardrobeItems.length === 0) return textContent;
  
    const processTextPart = (text) => {
      if (typeof text !== 'string') {
        console.warn('renderText received non-string text part:', text);
        return String(text);
      }
  
      let lastIndex = 0;
      const elements = [];
  
      wardrobeItems.forEach((item, index) => {
        if (typeof item === 'object' && item.name) {
          const startIndex = text.toLowerCase().indexOf(item.name.toLowerCase(), lastIndex);
          if (startIndex !== -1) {
            elements.push(text.slice(lastIndex, startIndex));
            elements.push(
              <span 
                key={`${index}-${startIndex}`}
                className="inline-block bg-avacasa-100 text-avacasa-800 px-1 rounded cursor-pointer hover:bg-avacasa-200"
                onClick={() => handleWardrobeItemClick(item)}
              >
                {text.slice(startIndex, startIndex + item.name.length)}
              </span>
            );
            lastIndex = startIndex + item.name.length;
          }
        }
      });
  
      elements.push(text.slice(lastIndex));
      return elements;
    };
  
    if (Array.isArray(textContent)) {
      return textContent.map((part, index) => 
        typeof part === 'object' ? part : processTextPart(String(part))
      );
    } else {
      return processTextPart(String(textContent));
    }
  };

  const handleWardrobeItemClick = (item) => {
    setSelectedWardrobeItem(item);
    setShowWardrobeModal(true);
  };

  return (
    <>
<div className={`flex ${sender === 'user' ? 'justify-end' : 'justify-start'} mb-4 w-full`}>
  <div className={`flex items-end ${sender === 'user' ? 'flex-row-reverse' : 'flex-row'} max-w-[85%]`}>
    <div className={`w-8 h-8 rounded-full overflow-hidden shadow-md flex-shrink-0 ${
      sender === 'user' ? 'ml-2' : 'mr-2'
    }`}>
      {sender === 'user' && userProfileImage ? (
        <img src={userProfileImage} alt="User" className="w-full h-full object-cover" />
      ) : (
        <div className={`w-full h-full flex items-center justify-center ${
          sender === 'user' 
            ? 'bg-gradient-to-br from-avacasa-400 to-avacasa-600'
            : 'bg-gradient-to-br from-gray-500 to-gray-700'
        }`}>
          {sender === 'ai' ? (
            <img 
              src={darkMode ? "https://ai.avacasa.ai/re/alighticon.png" : "https://ai.avacasa.ai/re/alighticon.png"} 
              alt="AVACASA Logo" 
              className="w-full h-full object-cover" // Ensures the image covers the circle
            />
          ) : (
            <span className="text-white text-sm font-bold">U</span>
          )}
        </div>
      )}
    </div>
    <div className={`px-4 py-3 rounded-2xl ${
      sender === 'user' 
        ? `${darkMode ? 'bg-avacasa-600 text-white' : 'bg-avacasa-500 text-white'} inline-block max-w-full`
        : `${darkMode ? 'bg-gray-800 text-gray-200' : 'bg-gray-100 text-gray-800'} w-full`
    }`}>
          {text && (
              <ReactMarkdown 
                remarkPlugins={[remarkGfm]} 
                className="text-sm leading-relaxed markdown-content"
                components={{
                  p: ({node, ...props}) => <p className="mb-2 last:mb-0">{renderText(props.children)}</p>,
                  ul: ({node, ...props}) => <ul className="list-disc pl-4 mb-2" {...props} />,
                  ol: ({node, ...props}) => <ol className="list-decimal pl-4 mb-2" {...props} />,
                  li: ({node, ...props}) => <li className="mb-1">{renderText(props.children)}</li>,
                  a: ({node, ...props}) => <a {...props} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline" />,
                }}
              >
                {text}
              </ReactMarkdown>
            )}
            {images && images.length > 0 && (
              <div className="mt-2 grid grid-cols-2 gap-2">
                {images.map((image, index) => (
                  <div key={index} className={`relative rounded-lg overflow-hidden ${isProcessing ? 'scanning-effect' : ''}`}>
                    <img src={image} alt={`Uploaded ${index + 1}`} className="w-full h-auto" />
                    {isProcessing && (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <span className="text-white text-sm font-medium bg-black bg-opacity-50 px-2 py-1 rounded">Analyzing...</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {showWardrobeModal && selectedWardrobeItem && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} p-6 rounded-lg max-w-md w-full`}>
      <h3 className="text-xl font-bold mb-4">{selectedWardrobeItem.name}</h3>
      <div className="w-full pb-[100%] relative mb-4">
        <img 
          src={selectedWardrobeItem.imageUrl} 
          alt={selectedWardrobeItem.name} 
          className="absolute inset-0 w-full h-full object-cover rounded-lg"
        />
      </div>
      <p className="mb-2"><strong>Category:</strong> {selectedWardrobeItem.category}</p>
      <p className="mb-2"><strong>Description:</strong> {selectedWardrobeItem.description}</p>
      <p className="mb-2"><strong>Color:</strong> {selectedWardrobeItem.color}</p>
      <p className="mb-2"><strong>Brand:</strong> {selectedWardrobeItem.brand}</p>
      <button 
        onClick={() => setShowWardrobeModal(false)}
        className="mt-4 px-4 py-2 bg-avacasa-500 text-white rounded hover:bg-avacasa-600 transition-colors"
      >
        Close
      </button>
    </div>
  </div>
      )}
    </>
  );
};

const Sidebar = ({ 
  isOpen, 
  onClose, 
  onNewConsultation, 
  conversations, 
  onSelectConversation, 
  onDeleteConversation, 
  onEditConversationName, 
  onManageMemories,
  onSkincareRoutineBuilder  // Add this line
}) => {
  const { darkMode } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredConversations = Array.isArray(conversations) ? conversations.filter(conv => 
    (conv && conv.name && typeof conv.name === 'string' && conv.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
    (conv && conv.messages && Array.isArray(conv.messages) && conv.messages.some(msg => 
      msg && msg.text && typeof msg.text === 'string' && msg.text.toLowerCase().includes(searchTerm.toLowerCase())
    ))
  ) : [];
  return (
<div className={`fixed inset-y-0 left-0 z-30 w-64 ${darkMode ? 'bg-gray-800' : 'bg-white'} overflow-y-auto transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
  <div className="p-4">
    <div className="flex items-center justify-between mb-4">
      {darkMode ? (
        <img 
          src="https://ai.avacasa.ai/re/logoavalight.png" 
          alt="AVACASA Dark Logo" 
          className="text-xl font-light tracking-wide" 
          style={{ width: '50%' }} // Set the width to 50%
        />
      ) : (
        <img 
          src="https://ai.avacasa.ai/re/logoavadark.png" 
          alt="AVACASA Light Logo" 
          className="text-xl font-light tracking-wide" 
          style={{ width: '50%' }} // Set the width to 50%
        />
      )}
      <button onClick={onClose} className="p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700">
        <ChevronLeft size={24} className={darkMode ? 'text-white' : 'text-gray-800'} />
          </button>
        </div>
        <button
          onClick={onNewConsultation}
          className={`w-full py-2 px-4 mb-4 rounded-full flex items-center justify-center ${
            darkMode ? 'bg-avacasa-600 text-white hover:bg-avacasa-700' : 'bg-avacasa-500 text-white hover:bg-avacasa-600'
          }`}
        >
          <PlusCircle size={18} className="mr-2" />
          New Consultation
        </button>
        <button
      onClick={onSkincareRoutineBuilder}
      className={`w-full py-2 px-4 mb-4 rounded-full flex items-center justify-center ${
        darkMode ? 'bg-avacasa-600 text-white hover:bg-avacasa-700' : 'bg-avacasa-500 text-white hover:bg-avacasa-600'
  }`}
    >
      <Droplet size={18} className="mr-2" />
      Skincare Routine
    </button>

        <button
          onClick={onManageMemories}
          className={`w-full py-2 px-4 mb-4 rounded-full ${
            darkMode ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
          }`}
        >
          Manage Memories
        </button>
        <div className="relative mb-4">
          <input
            type="text"
            placeholder="Search conversations..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={`w-full py-2 px-4 pr-10 rounded-full ${
              darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'
            }`}
          />
          <Search size={18} className={`absolute right-3 top-1/2 transform -translate-y-1/2 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
        </div>
        <div className="space-y-2">
          {filteredConversations.map((conv) => (
            <div key={conv.id} className={`p-2 rounded-lg cursor-pointer ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}>
              <div className="flex justify-between items-center">
                <span 
                  onClick={() => onSelectConversation(conv.id)}
                  className={`flex-grow truncate ${darkMode ? 'text-white' : 'text-gray-800'}`}
                >
                  {conv.name}
                </span>
                <button 
                  onClick={() => onDeleteConversation(conv.id)}
                  className={`ml-2 ${darkMode ? 'text-gray-400 hover:text-red-400' : 'text-gray-500 hover:text-red-500'}`}
                >
                  <X size={16} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Avacasa = () => {
  const [showHairAnalysis, setShowHairAnalysis] = useState(false);
  const [showBeautyAnalysis, setShowBeautyAnalysis] = useState(false);
  const [isVoiceModeActive, setIsVoiceModeActive] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const audioRef = useRef(null);
  const [isSpeechRecognitionActive, setIsSpeechRecognitionActive] = useState(false);
  const [hasFirstMessage, setHasFirstMessage] = useState(false);
  const [showSkincareRoutineBuilder, setShowSkincareRoutineBuilder] = useState(false);
  const [showStyleHoroscope, setShowStyleHoroscope] = useState(false);
  const [showStyleQuiz, setShowStyleQuiz] = useState(false);
  const [showTravelPacking, setShowTravelPacking] = useState(false);
  const [showInspirationOptions, setShowInspirationOptions] = useState(false);
  const [showDiscoveryOptions, setShowDiscoveryOptions] = useState(false);
const [showRateMyLook, setShowRateMyLook] = useState(false);
const [showRoastMyLook, setShowRoastMyLook] = useState(false);
const [showStyleTransformer, setShowStyleTransformer] = useState(false);
const [showVersusMode, setShowVersusMode] = useState(false);
  const [isWardrobeOpen, setIsWardrobeOpen] = useState(false);
    const [isStreaming, setIsStreaming] = useState(false);
    const [memories, setMemories] = useState([]);
    const [showAnalysisOptions, setShowAnalysisOptions] = useState(false);
    const [showColorAnalysis, setShowColorAnalysis] = useState(false);
    const [showSkinAnalysis, setShowSkinAnalysis] = useState(false);  
    const [isManageMemoriesOpen, setIsManageMemoriesOpen] = useState(false);
    const [isLoadingMemories, setIsLoadingMemories] = useState(false);
    const [activeFeature, setActiveFeature] = useState('chat');
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [imagePreviews, setImagePreviews] = useState([]);
    const { currentUser, darkMode, updateUserProfile, toggleDarkMode, logout, setCurrentUser } = useAuth();
    const fileInputRef = useRef(null);
    const contentRef = useRef(null);
    const messagesEndRef = useRef(null);
    const [contentHeight, setContentHeight] = useState('100%');
    const [streamingMessage, setStreamingMessage] = useState('');
    const [conversations, setConversations] = useState([]);
    const [currentConversationId, setCurrentConversationId] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isDesktop, setIsDesktop] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const navigate = useNavigate();
  
    const scrollToBottom = () => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ 
          behavior: hasFirstMessage ? "smooth" : "auto" 
        });
      }
    };
    
  
    const handleVoiceMessage = (message) => {
      setMessages(prevMessages => [...prevMessages, { id: Date.now(), text: message, sender: 'ai' }]);
    };  
    useEffect(() => {
      if (isVoiceModeActive && !audioRef.current) {
        audioRef.current = new (window.AudioContext || window.webkitAudioContext)();
      }
    }, [isVoiceModeActive]);

    const toggleVoiceMode = () => {
      setIsVoiceModeActive(!isVoiceModeActive);
    };
  
  
        
    const handleVersusMode = async (imageFiles, previewUrls) => {
      setShowVersusMode(false);
      setIsProcessing(true);
    
      const newMessageId = Date.now();
      const newMessage = {
        id: newMessageId,
        sender: 'user',
        images: previewUrls,
        isProcessing: true
      };
    
      setMessages(prevMessages => [...prevMessages, newMessage]);
    
      try {
        const imageUrls = await Promise.all(imageFiles.map(uploadImageToFirebase));
        
        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === newMessageId
              ? { ...msg, images: imageUrls }
              : msg
          )
        );
        
        await performAnalysis(imageUrls, newMessageId, 'versusMode');
      } catch (error) {
        console.error('Error uploading images or performing Versus Mode analysis:', error);
      } finally {
        setIsProcessing(false);
        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === newMessageId
              ? { ...msg, isProcessing: false }
              : msg
          )
        );
      }
    };

    const handleHairAnalysisClick = () => {
      setShowAnalysisOptions(false);
      setShowHairAnalysis(true);
    };
  
    const handleHairImageSelect = async (imageFile, previewUrl, hairInfo) => {
      setShowHairAnalysis(false);
      setIsProcessing(true);
    
      const newMessageId = Date.now();
      const newMessage = {
        id: newMessageId,
        sender: 'user',
        images: [previewUrl],
        isProcessing: true
      };
    
      setMessages(prevMessages => [...prevMessages, newMessage]);
    
      try {
        const imageUrl = await uploadImageToFirebase(imageFile);
        
        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === newMessageId
              ? { ...msg, images: [imageUrl] }
              : msg
          )
        );
    
        await performHairAnalysis(imageUrl, newMessageId, hairInfo);
      } catch (error) {
        console.error('Error uploading image or performing hair analysis:', error);
      } finally {
        setIsProcessing(false);
        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === newMessageId
              ? { ...msg, isProcessing: false }
              : msg
          )
        );
      }
    };
  

    const handleBeautyAnalysisClick = () => {
      setShowAnalysisOptions(false);
      setShowBeautyAnalysis(true);
    };
    
    const handleSpeechRecognition = () => {
      setIsSpeechRecognitionActive(true);
      const stopRecognition = startSpeechRecognition(
        (recognizedText) => {
          setInput(prevInput => prevInput + ' ' + recognizedText);
          setIsSpeechRecognitionActive(false);
        },
        (error) => {
          console.error('Speech recognition error:', error);
          setIsSpeechRecognitionActive(false);
        }
      );
    
      // Automatically stop recognition after 10 seconds
      setTimeout(() => {
        stopRecognition();
        setIsSpeechRecognitionActive(false);
      }, 10000);
    };

    const handleUpdateMemory = async (oldKey, updatedMemory) => {
      try {
        await updateMemory(currentUser.uid, oldKey, updatedMemory);
        setMemories(prevMemories => prevMemories.map(m => 
          m.key === oldKey ? { ...m, ...updatedMemory } : m
        ));
      } catch (error) {
        console.error("Error updating memory:", error);
      }
    };
  
    const handleInspirationClick = () => {
      setActiveFeature('inspiration');
      setShowInspirationOptions(true);
    };

    const handleStyleHoroscopeClick = () => {
      setShowInspirationOptions(false);
      setShowStyleHoroscope(true);
    };
    
    const handleStyleQuizClick = () => {
      setShowInspirationOptions(false);
      setShowStyleQuiz(true);
    };
    
    const handleTravelPackingClick = () => {
      setShowInspirationOptions(false);
      setShowTravelPacking(true);
    };

    const handleStyleHoroscopeSubmit = async (data) => {
      setShowStyleHoroscope(false);
      setIsProcessing(true);
    
    
      const newMessageId = Date.now();
      const newMessage = {
        id: newMessageId,
        sender: 'user',
        text: `Style Horoscope for ${data.zodiacSign} on ${data.date}${data.occasion ? ` for ${data.occasion}` : ''}`,
      };
    
      setMessages(prevMessages => [...prevMessages, newMessage]);
    
      try {
        await performAnalysis(null, newMessageId, 'styleHoroscope', data);
      } catch (error) {
        console.error('Error generating style horoscope:', error);
      } finally {
        setIsProcessing(false);
      }
    };
    
    
    const handleStyleQuizSubmit = async (data) => {
      setShowStyleQuiz(false);
      setIsProcessing(true);
    
      const newMessageId = Date.now();
      const newMessage = {
        id: newMessageId,
        sender: 'user',
        text: 'Style Quiz Results',
      };
    
      setMessages(prevMessages => [...prevMessages, newMessage]);
    
      try {
        await performAnalysis(null, newMessageId, 'styleQuiz', data);
      } catch (error) {
        console.error('Error processing style quiz:', error);
      } finally {
        setIsProcessing(false);
      }
    };
    
    const handleTravelPackingSubmit = async (data) => {
      setShowTravelPacking(false);
      setIsProcessing(true);
    
      const newMessageId = Date.now();
      const newMessage = {
        id: newMessageId,
        sender: 'user',
        text: `Travel Packing List for ${data.destination} from ${data.startDate} to ${data.endDate}`,
      };
    
      setMessages(prevMessages => [...prevMessages, newMessage]);
    
      try {
        await performAnalysis(null, newMessageId, 'travelPacking', data);
      } catch (error) {
        console.error('Error generating travel packing list:', error);
      } finally {
        setIsProcessing(false);
      }
    };
    
    const handleDeleteMemory = async (memoryKey) => {
      try {
        await deleteMemory(currentUser.uid, memoryKey);
        setMemories(prevMemories => prevMemories.filter(m => m.key !== memoryKey));
      } catch (error) {
        console.error("Error deleting memory:", error);
      }
    };
      
    const handleDeleteAllMemories = async () => {
      try {
        await deleteAllMemories(currentUser.uid);
        setMemories([]);
      } catch (error) {
        console.error("Error deleting all memories:", error);
      }
    };
          
    const handleDiscoveryClick = () => {
      setShowDiscoveryOptions(true);
    };
    
    const handleRateMyLookClick = () => {
      setShowDiscoveryOptions(false);
      setShowRateMyLook(true);
    };
    
    const handleRoastMyLookClick = () => {
      setShowDiscoveryOptions(false);
      setShowRoastMyLook(true);
    };
    
    const handleStyleTransformerClick = () => {
      setShowDiscoveryOptions(false);
      setShowStyleTransformer(true);
    };
    
    const handleVersusModeClick = () => {
      setShowDiscoveryOptions(false);
      setShowVersusMode(true);
    };
    
    const handleAnalysisClick = () => {
      setShowAnalysisOptions(true);
    };
    
    const handleColorAnalysisClick = () => {
      setShowAnalysisOptions(false);
      setShowColorAnalysis(true);
    };

    const handleImageSelect = async (imageFile, previewUrl, feature, additionalInfo = null) => {
      setShowRateMyLook(false);
      setShowRoastMyLook(false);
      setShowStyleTransformer(false);
      setShowColorAnalysis(false);
      setShowBeautyAnalysis(false);
      setIsProcessing(true);
    
      const newMessageId = Date.now();
      const newMessage = {
        id: newMessageId,
        sender: 'user',
        images: [previewUrl],
        isProcessing: true
      };
    
      setMessages(prevMessages => [...prevMessages, newMessage]);
    
      try {
        const imageUrl = await uploadImageToFirebase(imageFile);
        
        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === newMessageId
              ? { ...msg, images: [imageUrl] }
              : msg
          )
        );
    
        switch (feature) {
          case 'beautyAnalysis':
            await performBeautyAnalysis(imageUrl, newMessageId, additionalInfo);
            break;
          case 'colorAnalysis':
            await performColorAnalysis(imageUrl, newMessageId);
            break;
          default:
            await performAnalysis([imageUrl], newMessageId, feature);
        }
      } catch (error) {
        console.error(`Error uploading image or performing ${feature}:`, error);
      } finally {
        setIsProcessing(false);
        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === newMessageId
              ? { ...msg, isProcessing: false }
              : msg
          )
        );
      }
    };
    
    const performHairAnalysis = async (imageUrl, messageId, hairInfo) => {
      setIsProcessing(true);
      setStreamingMessage('');
    
      try {
        const result = await generateResponse(
          [{ text: "", sender: "user", images: [imageUrl] }],
          { ...currentUser, hairPreferences: hairInfo },
          memories,
          (chunk) => {
            setStreamingMessage(prev => prev + chunk);
          },
          'hairAnalysis',
          imageUrl,
          null, // skinInfo
          [], // wardrobe
          null, // versusImages
          null, // inspirationData
          null, // beautyInfo
          hairInfo // Pass hairInfo here
        );
          
        const { aiResponse, memoryOperations } = result;
    
        // Handle memory operations if needed
        if (Array.isArray(memoryOperations)) {
          for (const operation of memoryOperations) {
            if (operation.action === 'add' || operation.action === 'update') {
              await saveMemory(currentUser.uid, { key: operation.key, value: operation.value });
              setMemories(prevMemories => {
                const index = prevMemories.findIndex(m => m.key === operation.key);
                if (index !== -1) {
                  const updatedMemories = [...prevMemories];
                  updatedMemories[index] = { key: operation.key, value: operation.value };
                  return updatedMemories;
                } else {
                  return [...prevMemories, { key: operation.key, value: operation.value }];
                }
              });
            }
          }
        }
    
        const aiMessage = { id: Date.now(), text: aiResponse.trim(), sender: "ai" };
        
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages, aiMessage];
          updateFirestore(updatedMessages, messageId);
          return updatedMessages;
        });
    
      } catch (error) {
        console.error('Error generating hair analysis:', error);
        const errorMessage = { 
          id: Date.now(),
          text: "I'm sorry, I encountered an error while performing the hair analysis. Please try again.", 
          sender: "ai" 
        };
        setMessages(prevMessages => [...prevMessages, errorMessage]);
      } finally {
        setIsProcessing(false);
        setStreamingMessage('');
      }
    };
    
    
const performBeautyAnalysis = async (imageUrl, messageId, beautyInfo) => {
  setIsProcessing(true);
  setStreamingMessage('');

  try {
    const result = await generateResponse(
      [{ text: "", sender: "user", images: [imageUrl] }],
      { ...currentUser, beautyPreferences: beautyInfo.beautyPreferences },
      memories,
      (chunk) => {
        setStreamingMessage(prev => prev + chunk);
      },
      'beautyAnalysis',
      imageUrl,
      null, // skinInfo
      [], // wardrobe
      null, // versusImages
      null, // inspirationData
      beautyInfo // Pass beautyInfo here
    );
      
    const { aiResponse, memoryOperations } = result;

    // Handle memory operations if needed
    if (Array.isArray(memoryOperations)) {
      for (const operation of memoryOperations) {
        if (operation.action === 'add' || operation.action === 'update') {
          await saveMemory(currentUser.uid, { key: operation.key, value: operation.value });
          setMemories(prevMemories => {
            const index = prevMemories.findIndex(m => m.key === operation.key);
            if (index !== -1) {
              const updatedMemories = [...prevMemories];
              updatedMemories[index] = { key: operation.key, value: operation.value };
              return updatedMemories;
            } else {
              return [...prevMemories, { key: operation.key, value: operation.value }];
            }
          });
        }
      }
    }

    const aiMessage = { id: Date.now(), text: aiResponse.trim(), sender: "ai" };
    
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages, aiMessage];
      updateFirestore(updatedMessages, messageId);
      return updatedMessages;
    });

  } catch (error) {
    console.error('Error generating beauty analysis:', error);
    const errorMessage = { 
      id: Date.now(),
      text: "I'm sorry, I encountered an error while performing the beauty analysis. Please try again.", 
      sender: "ai" 
    };
    setMessages(prevMessages => [...prevMessages, errorMessage]);
  } finally {
    setIsProcessing(false);
    setStreamingMessage('');
  }
};



const performColorAnalysis = async (imageUrl, messageId) => {
  setIsProcessing(true);
  setStreamingMessage('');

  try {
    const result = await generateResponse(
      [{ text: "", sender: "user", images: [imageUrl] }],
      currentUser,
      memories,
      (chunk) => {
        setStreamingMessage(prev => prev + chunk);
      },
      'colorAnalysis',
      imageUrl
    );
      
    const { aiResponse, memoryOperations } = result;

    // Handle memory operations
    if (Array.isArray(memoryOperations)) {
      for (const operation of memoryOperations) {
        if (operation.action === 'add' || operation.action === 'update') {
          await saveMemory(currentUser.uid, { key: operation.key, value: operation.value });
          setMemories(prevMemories => {
            const index = prevMemories.findIndex(m => m.key === operation.key);
            if (index !== -1) {
              // Update existing memory
              const updatedMemories = [...prevMemories];
              updatedMemories[index] = { key: operation.key, value: operation.value };
              return updatedMemories;
            } else {
              // Add new memory
              return [...prevMemories, { key: operation.key, value: operation.value }];
            }
          });
        }
      }
    }

    const aiMessage = { id: Date.now(), text: aiResponse.trim(), sender: "ai" };
    
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages, aiMessage];
      updateFirestore(updatedMessages, messageId);
      return updatedMessages;
    });

  } catch (error) {
    console.error('Error generating color analysis:', error);
    const errorMessage = { 
      id: Date.now(),
      text: "I'm sorry, I encountered an error while performing the color analysis. Please try again.", 
      sender: "ai" 
    };
    setMessages(prevMessages => [...prevMessages, errorMessage]);
  } finally {
    setIsProcessing(false);
    setStreamingMessage('');
  }
};

const performAnalysis = async (imageUrls, messageId, feature, inspirationData = null) => {
  setIsProcessing(true);
  setStreamingMessage('');

  try {
    const userMessage = {
      text: inspirationData ? JSON.stringify(inspirationData) : "",
      sender: "user",
      images: Array.isArray(imageUrls) ? imageUrls : (imageUrls ? [imageUrls] : [])
    };

    const result = await generateResponse(
      [userMessage],
      currentUser,
      memories,
      (chunk) => {
        setStreamingMessage(prev => prev + chunk);
      },
      feature,
      Array.isArray(imageUrls) ? imageUrls[0] : imageUrls,
      null,
      [],
      Array.isArray(imageUrls) ? imageUrls : null,
      inspirationData
    );
    
    const { aiResponse, memoryOperations } = result;

    // Handle memory operations
    if (Array.isArray(memoryOperations)) {
      for (const operation of memoryOperations) {
        if (operation.action === 'add' || operation.action === 'update') {
          await saveMemory(currentUser.uid, { key: operation.key, value: operation.value });
          setMemories(prevMemories => {
            const index = prevMemories.findIndex(m => m.key === operation.key);
            if (index !== -1) {
              // Update existing memory
              const updatedMemories = [...prevMemories];
              updatedMemories[index] = { key: operation.key, value: operation.value };
              return updatedMemories;
            } else {
              // Add new memory
              return [...prevMemories, { key: operation.key, value: operation.value }];
            }
          });
        }
      }
    }

    const aiMessage = { id: Date.now(), text: aiResponse.trim(), sender: "ai" };
    
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages, aiMessage];
      updateFirestore(updatedMessages, messageId);
      return updatedMessages;
    });

  } catch (error) {
    console.error(`Error generating ${feature}:`, error);
    const errorMessage = { 
      id: Date.now(),
      text: `I'm sorry, I encountered an error while performing the ${feature}. Please try again.`, 
      sender: "ai" 
    };
    setMessages(prevMessages => [...prevMessages, errorMessage]);
  } finally {
    setIsProcessing(false);
    setStreamingMessage('');
  }
};    
    const uploadImageToFirebase = async (file) => {
      const imageRef = ref(storage, `images/${currentUser.uid}/${Date.now()}_${file.name}`);
      await uploadBytes(imageRef, file);
      return getDownloadURL(imageRef);
    };
    
    // Separate function to update Firestore
      const updateFirestore = async (messages, messageId) => {
        if (currentConversationId) {
          const conversationRef = doc(db, `users/${currentUser.uid}/conversations`, currentConversationId);
          try {
            await updateDoc(conversationRef, {
              messages: messages,
              updatedAt: new Date()
            });
          } catch (error) {
            console.error('Error updating Firestore:', error);
          }
        } else {
          // Create a new conversation if one doesn't exist
          const newConversation = {
            name: "Color Analysis",
            messages: messages,
            createdAt: new Date(),
            updatedAt: new Date()
          };
          try {
            const docRef = await addDoc(collection(db, `users/${currentUser.uid}/conversations`), newConversation);
            setCurrentConversationId(docRef.id);
          } catch (error) {
            console.error('Error creating new conversation in Firestore:', error);
          }
        }
      };
      const handleSkinAnalysisClick = () => {
        setShowAnalysisOptions(false);
        setShowSkinAnalysis(true);
      };
    
      const handleSkinImageSelect = async (imageFile, previewUrl, skinInfo) => {
        setShowSkinAnalysis(false);
        setIsProcessing(true);
      
        const newMessageId = Date.now();
        const newMessage = {
          id: newMessageId,
          sender: 'user',
          images: [previewUrl],
          isProcessing: true
        };
      
        setMessages(prevMessages => [...prevMessages, newMessage]);
      
        try {
          const imageUrl = await uploadImageToFirebase(imageFile);
          
          setMessages(prevMessages => 
            prevMessages.map(msg => 
              msg.id === newMessageId
                ? { ...msg, images: [imageUrl] }
                : msg
            )
          );
      
          await performSkinAnalysis(imageUrl, newMessageId, skinInfo);
        } catch (error) {
          console.error('Error uploading image or performing skin analysis:', error);
          // Handle error (e.g., show error message to user)
        } finally {
          setIsProcessing(false);
          setMessages(prevMessages => 
            prevMessages.map(msg => 
              msg.id === newMessageId
                ? { ...msg, isProcessing: false }
                : msg
            )
          );
        }
      };
      
          
      const performSkinAnalysis = async (imageUrl, messageId, skinInfo) => {
        setIsProcessing(true);
        setStreamingMessage('');
      
        try {
          const result = await generateResponse(
            [{ text: "", sender: "user", images: [imageUrl] }],
            { ...currentUser, skinType: skinInfo.skinType }, // Use the most recent skin type
            memories,
            (chunk) => {
              setStreamingMessage(prev => prev + chunk);
            },
            'skinAnalysis',
            imageUrl,
            skinInfo
          );
            
                const { aiResponse, memoryOperations } = result;
      
          // Handle memory operations
          if (Array.isArray(memoryOperations)) {
            for (const operation of memoryOperations) {
              if (operation.type === 'ADD_MEMORY') {
                await saveMemory(currentUser.uid, { key: operation.key, value: operation.value });
                setMemories(prevMemories => [...prevMemories, { key: operation.key, value: operation.value }]);
              }
            }
          }
            
          const aiMessage = { id: Date.now(), text: aiResponse.trim(), sender: "ai" };
          
          // Update messages state
          setMessages(prevMessages => {
            const updatedMessages = [...prevMessages, aiMessage];
            
            // Update Firestore
            updateFirestore(updatedMessages, messageId);
            
            return updatedMessages;
          });
      
        } catch (error) {
          console.error('Error generating skin analysis:', error);
          const errorMessage = { 
            id: Date.now(),
            text: "I'm sorry, I encountered an error while performing the skin analysis. Please try again.", 
            sender: "ai" 
          };
          setMessages(prevMessages => [...prevMessages, errorMessage]);
        } finally {
          setIsProcessing(false);
          setStreamingMessage('');
        }
      };
    
      const updateMemoriesInFirestore = async (userId, updatedMemories) => {
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, { memories: updatedMemories });
      };
    
useEffect(() => {
  if (!showInspirationOptions && !showStyleHoroscope && !showStyleQuiz && !showTravelPacking && activeFeature === 'inspiration') {
    setActiveFeature('chat');
  }
}, [showInspirationOptions, showStyleHoroscope, showStyleQuiz, showTravelPacking, activeFeature]);  
useEffect(() => {
  if (hasFirstMessage) {
    scrollToBottom();
  }
}, [messages, streamingMessage, hasFirstMessage]);    

useEffect(() => {
  const fetchMemories = async () => {
    if (currentUser) {
      setIsLoadingMemories(true);
      try {
        const userMemories = await getMemories(currentUser.uid);
        setMemories(userMemories);
      } catch (error) {
        console.error('Error fetching memories:', error);
      } finally {
        setIsLoadingMemories(false);
      }
    }
  };
  fetchMemories();
}, [currentUser]);

  useEffect(() => {
const updateContentHeight = () => {
  if (contentRef.current) {
    const windowHeight = window.innerHeight;
    const headerHeight = document.querySelector('header').offsetHeight;
    const mobileNavHeight = document.querySelector('.mobile-nav')?.offsetHeight || 0;
    const footerHeight = document.querySelector('footer').offsetHeight;
    const newHeight = windowHeight - headerHeight - mobileNavHeight - footerHeight;
    setContentHeight(`${newHeight}px`);
  }
};


    const handleResize = () => {
      updateContentHeight();
      const newIsDesktop = window.innerWidth >= 768;
      setIsDesktop(newIsDesktop);
      setIsSidebarOpen(newIsDesktop);
    };

    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!currentUser) return;

    const q = query(
      collection(db, `users/${currentUser.uid}/conversations`),
      orderBy('updatedAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const conversationsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setConversations(conversationsData);
    });

    return unsubscribe;
  }, [currentUser]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const startNewConsultation = async () => {
    const newConversation = {
      name: 'New Consultation',
      messages: [],
      createdAt: new Date(),
      updatedAt: new Date()
    };

    const docRef = await addDoc(collection(db, `users/${currentUser.uid}/conversations`), newConversation);
    setCurrentConversationId(docRef.id);
    setMessages([]);
  };

  const selectConversation = (id) => {
    setCurrentConversationId(id);
    const conversation = conversations.find(conv => conv.id === id);
    setMessages(conversation.messages);
    if (!isDesktop) {
      setIsSidebarOpen(false);
    }
  };

  const deleteConversation = async (id) => {
    await deleteDoc(doc(db, `users/${currentUser.uid}/conversations`, id));
    if (currentConversationId === id) {
      setCurrentConversationId(null);
      setMessages([]);
    }
  };

  const editConversationName = async (id, newName) => {
    await updateDoc(doc(db, `users/${currentUser.uid}/conversations`, id), { name: newName });
  };

  const handleSaveProfile = async (profileData) => {
    await updateDoc(doc(db, 'users', currentUser.uid), profileData);
  };

  const handleSend = useCallback(async () => {
    if (!currentUser || (!input.trim() && imagePreviews.length === 0)) return;
  
    const newUserMessage = { id: Date.now(), text: input, sender: "user", images: imagePreviews };
  
    setMessages(prevMessages => {
      const updatedMessages = [...prevMessages, newUserMessage];
      if (!hasFirstMessage && updatedMessages.length > 1) {
        setHasFirstMessage(true);
      }
      return updatedMessages;
    });
      setInput('');
    setImagePreviews([]);
    setIsProcessing(true);

  
  
    let conversationRef;
    if (currentConversationId) {
      conversationRef = doc(db, `users/${currentUser.uid}/conversations`, currentConversationId);
      await updateDoc(conversationRef, {
        messages: [...messages, newUserMessage],
        updatedAt: new Date()
      });
    } else {
      const newConversation = {
        name: input.substring(0, 30),
        messages: [newUserMessage],
        createdAt: new Date(),
        updatedAt: new Date()
      };
      conversationRef = await addDoc(collection(db, `users/${currentUser.uid}/conversations`), newConversation);
      setCurrentConversationId(conversationRef.id);
    }
  
    try {
      const streamingMessageId = Date.now();
      setMessages(prevMessages => [...prevMessages, { id: streamingMessageId, text: '', sender: "ai", isStreaming: true }]);
  
      let fullResponse = '';
  
      // Fetch the user's wardrobe data
      const wardrobeSnapshot = await getDocs(collection(db, `users/${currentUser.uid}/wardrobe`));
      const wardrobeData = wardrobeSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      // Generate AI response
      const { aiResponse, memoryOperations } = await generateResponse(
        [...messages, newUserMessage],
        currentUser,
        memories,
        (chunk) => {
          fullResponse += chunk;
          setMessages(prevMessages => prevMessages.map(msg => 
            msg.id === streamingMessageId ? { ...msg, text: fullResponse } : msg
          ));
        },
        null, // feature
        null, // imageUrl
        null, // skinInfo
        wardrobeData // Pass the wardrobe data here
      );
  
      // Identify mentioned wardrobe items
      const mentionedItems = wardrobeData.filter(item => 
        aiResponse.toLowerCase().includes(item.name.toLowerCase())
      );
      // Replace streaming message with final message
      setMessages(prevMessages => prevMessages.map(msg => 
        msg.id === streamingMessageId ? { 
          id: streamingMessageId, 
          text: aiResponse.trim(), 
          sender: "ai", 
          isStreaming: false,
          wardrobeItems: mentionedItems
        } : msg
      ));
  
      // Update Firestore with AI response
await updateDoc(conversationRef, {
  messages: [...messages, newUserMessage, { 
    text: aiResponse.trim(), 
    sender: "ai",
    wardrobeItems: mentionedItems.map(item => item.id) // Store only IDs in Firestore
  }],
  updatedAt: new Date()
});  
      // Handle memory operations
      if (memoryOperations && memoryOperations.length > 0) {
        for (const operation of memoryOperations) {
          if (operation.action === 'add' || operation.action === 'update') {
            await saveMemory(currentUser.uid, { key: operation.key, value: operation.value });
            setMemories(prevMemories => {
              const index = prevMemories.findIndex(m => m.key === operation.key);
              if (index !== -1) {
                // Update existing memory
                const updatedMemories = [...prevMemories];
                updatedMemories[index] = { key: operation.key, value: operation.value };
                return updatedMemories;
              } else {
                // Add new memory
                return [...prevMemories, { key: operation.key, value: operation.value }];
              }
            });
          } else if (operation.action === 'retrieve') {
            // Log retrieved memory for debugging
          }
        }
      }
  
    } catch (error) {
      console.error('Error generating AI response:', error);
      let errorMessage = { 
        id: Date.now(),
        text: "I'm sorry, I encountered an error while processing your request. Please try again.", 
        sender: "ai" 
      };
  
      if (error.message.includes('HTTP error!')) {
        errorMessage.text = "There seems to be a problem connecting to the AI service. Please check your internet connection and try again.";
      } else if (error.message.includes('TypeError')) {
        errorMessage.text = "I encountered an unexpected data format. Our team has been notified and is working on a fix. Please try again later.";
      }
      
      await updateDoc(conversationRef, {
        messages: [...messages, newUserMessage, errorMessage],
        updatedAt: new Date()
      });
      
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsProcessing(false);
    }
  }, [input, messages, imagePreviews, currentConversationId, currentUser, memories, saveMemory, generateResponse]);
  
  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 4) {
      alert("You can only upload up to 4 images at once.");
      return;
    }
  
    try {
      const uploadPromises = files.map(async (file) => {
        const imageRef = ref(storage, `images/${currentUser.uid}/${Date.now()}_${file.name}`);
        await uploadBytes(imageRef, file);
        return getDownloadURL(imageRef);
      });
  
      const uploadedUrls = await Promise.all(uploadPromises);
      setImagePreviews(prevPreviews => [...prevPreviews, ...uploadedUrls].slice(0, 4));
    } catch (error) {
      console.error('Error uploading image:', error);
      alert("There was an error uploading the image. Please try again.");
    }
  };

  const removeImagePreview = (index) => {
    setImagePreviews(prevPreviews => prevPreviews.filter((_, i) => i !== index));
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  return (
    <div className={`flex h-screen ${darkMode ? 'bg-gray-900' : 'bg-white'}`}>
<Sidebar
  isOpen={isSidebarOpen}
  onClose={toggleSidebar}
  onNewConsultation={startNewConsultation}
  conversations={conversations}
  onSelectConversation={selectConversation}
  onDeleteConversation={deleteConversation}
  onEditConversationName={editConversationName}
  onManageMemories={() => setIsManageMemoriesOpen(true)}
  onSkincareRoutineBuilder={() => setShowSkincareRoutineBuilder(true)}
/>


<ManageMemories
      isOpen={isManageMemoriesOpen}
      onClose={() => setIsManageMemoriesOpen(false)}
      memories={memories}
      onUpdateMemory={handleUpdateMemory}
      onDeleteMemory={handleDeleteMemory}
      onDeleteAllMemories={handleDeleteAllMemories}
      darkMode={darkMode}
    />
      
      <div className="flex-1 flex flex-col">
<header className={`${darkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b px-4 py-3 flex items-center sticky top-0 z-10`}>
  <div className="flex items-center space-x-3 w-1/4">
    <button onClick={toggleSidebar}>
      <Menu size={22} className={darkMode ? 'text-avacasa-400' : 'text-avacasa-600'} />
    </button>
    <h1 className={`text-xl font-light tracking-wide ${darkMode ? 'text-avacasa-400' : 'text-avacasa-600'}`}>
  {darkMode ? (
    <img 
      src="https://ai.avacasa.ai/re/logoavalight.png" 
      alt="AVACASA Dark Logo" 
      className="w-[40vw] max-w-[130px] min-w-[120px] md:w-28 lg:w-32" 
    />
  ) : (
    <img 
      src="https://ai.avacasa.ai/re/logoavadark.png" 
      alt="AVACASA Light Logo" 
      className="w-[40vw] max-w-[130px] min-w-[120px] md:w-28 lg:w-32" 
    />
  )}
</h1>
  </div>
  
  <div className="flex-1 flex justify-center">
    <div className="hidden md:flex items-center space-x-4">
      <FeatureButton 
        icon={BarChart2} 
        label="Analysis" 
        isActive={activeFeature === 'analysis'} 
        onClick={handleAnalysisClick} 
      />
      <FeatureButton 
        icon={Shirt} 
        label="Wardrobe" 
        isActive={isWardrobeOpen} 
        onClick={() => setIsWardrobeOpen(true)} 
      />            
      <FeatureButton 
        icon={Compass} 
        label="Discovery" 
        isActive={activeFeature === 'discovery'} 
        onClick={handleDiscoveryClick} 
      />            
      <FeatureButton 
        icon={Lightbulb} 
        label="Inspiration" 
        isActive={activeFeature === 'inspiration'} 
        onClick={handleInspirationClick} 
      />
    </div>
  </div>
  
  <div className="flex items-center space-x-2 w-1/4 justify-end">
    <button 
      onClick={toggleDarkMode} 
      className={`p-2 rounded-full ${darkMode ? 'bg-gray-700 text-avacasa-400' : 'bg-gray-100 text-avacasa-600'} hover:bg-opacity-80 transition-colors`}
    >
      {darkMode ? <Sun size={18} /> : <Moon size={18} />}
    </button>
    <button 
      onClick={() => setIsProfileOpen(true)}
      className={`p-2 rounded-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} hover:bg-opacity-80 transition-colors`}
    >
      <User size={18} />
    </button>
    <button 
      onClick={toggleVoiceMode}
      className={`p-2 rounded-full ${isVoiceModeActive ? 'bg-avacasa-500 text-white' : darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} hover:bg-opacity-80 transition-colors`}
    >
      <Mic size={18} />
    </button>
    <button 
      onClick={handleLogout}
      className={`p-2 rounded-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} hover:bg-opacity-80 transition-colors`}
    >
      <LogOut size={18} />
    </button>
  </div>
</header>
        <div className={`md:hidden flex justify-between w-full p-2 ${darkMode ? 'bg-gray-800' : 'bg-gray-100'} sticky top-[57px] z-10 mobile-nav`}>
          <MobileFeatureButton icon={BarChart2} label="Analysis" isActive={activeFeature === 'analysis'} onClick={handleAnalysisClick} />
          <MobileFeatureButton 
  icon={Shirt} 
  label="Wardrobe" 
  isActive={isWardrobeOpen} 
  onClick={() => setIsWardrobeOpen(true)} 
/>          
<MobileFeatureButton 
  icon={Compass} 
  label="Discovery" 
  isActive={activeFeature === 'discovery'} 
  onClick={handleDiscoveryClick} 
/>
<MobileFeatureButton 
  icon={Lightbulb} 
  label="Inspiration" 
  isActive={activeFeature === 'inspiration'} 
  onClick={handleInspirationClick} 
/>
        </div>

        <main className="flex-1 overflow-hidden flex flex-col" ref={contentRef} style={{ height: contentHeight }}>
  {activeFeature === 'chat' && (
    <div className="flex-1 overflow-y-auto px-4 py-4 max-w-4xl mx-auto w-full">
      {isLoadingMemories && (
        <div className="text-center py-4">
                {/* Add loading indicator here if needed */}
                </div>
      )}
{messages.map((message) => (
  <Message 
    key={message.id} 
    text={message.text} 
    sender={message.sender} 
    images={message.images}
    userProfileImage={currentUser.profileImageUrl}
    isProcessing={message.isProcessing}
    wardrobeItems={message.wardrobeItems}
  />
      ))}
      {isStreaming && (
        <Message 
          text={streamingMessage} 
          sender="ai" 
          isStreaming={true}
        />
      )}
      {isProcessing && !streamingMessage && (
        <div className="flex justify-start mb-4">
          <div className={`px-4 py-3 rounded-2xl ${darkMode ? 'bg-gray-800 text-gray-200' : 'bg-gray-100 text-gray-800'}`}>
            <div className="flex space-x-2">
              <div className="w-2 h-2 rounded-full bg-gray-400 animate-pulse"></div>
              <div className="w-2 h-2 rounded-full bg-gray-400 animate-pulse delay-75"></div>
              <div className="w-2 h-2 rounded-full bg-gray-400 animate-pulse delay-150"></div>
            </div>
          </div>
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  )}
{isWardrobeOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className={`${darkMode ? 'bg-gray-800' : 'bg-white'} rounded-lg p-6 w-11/12 h-5/6 overflow-auto`}>
      <div className="flex justify-between items-center mb-4">
      <h2 className={`text-2xl font-bold mb-4 ${darkMode ? 'text-white' : 'text-gray-800'}`}>
        {currentUser.name ? `${currentUser.name}'s Wardrobe` : 'Your Wardrobe'}
</h2>        <button 
          onClick={() => setIsWardrobeOpen(false)}
          className={`p-2 rounded-full ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
        >
          <X size={24} />
        </button>
      </div>
      <Wardrobe />
    </div>
  </div>
)}  


        </main>
        <footer className={`${darkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-t p-3 sticky bottom-0 z-10`}>
  <div className={`max-w-4xl mx-auto flex flex-col`}>
    {imagePreviews.length > 0 && (
      <div className="mb-2 flex items-center space-x-2 overflow-x-auto">
        {imagePreviews.map((preview, index) => (
          <div key={index} className="relative">
            <img src={preview} alt={`Preview ${index + 1}`} className="h-16 w-auto rounded-md" />
            <button
              onClick={() => removeImagePreview(index)}
              className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors"
            >
              <X size={12} />
            </button>
          </div>
        ))}
      </div>
    )}
    <div className={`flex items-center space-x-3 ${darkMode ? 'bg-gray-700' : 'bg-gray-100'} rounded-full p-2`}>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInputRef}
        className="hidden"
        multiple
      />
      <button 
        onClick={() => fileInputRef.current.click()}
        className={`p-2 ${darkMode ? 'text-gray-400 hover:text-avacasa-400' : 'text-gray-400 hover:text-avacasa-600'} transition-colors`}
      >
        <Image size={18} />
      </button>
      <button
        onClick={handleSpeechRecognition}
        disabled={isSpeechRecognitionActive}
        className={`p-2 ${
          isSpeechRecognitionActive
            ? 'text-avacasa-600'
            : darkMode
            ? 'text-gray-400 hover:text-avacasa-400'
            : 'text-gray-400 hover:text-avacasa-600'
        } transition-colors`}
      >
        <Mic size={18} />
      </button>
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && handleSend()}
        placeholder="Ask Ava..."
        className={`flex-1 bg-transparent border-none focus:outline-none ${darkMode ? 'text-white placeholder-gray-400' : 'text-gray-800 placeholder-gray-400'} text-sm`}
      />
      <button 
        onClick={() => handleSend()} 
        className={`${darkMode ? 'bg-avacasa-600 text-white hover:bg-avacasa-700' : 'bg-avacasa-500 text-white hover:bg-avacasa-600'} p-2 rounded-full transition-colors`}
      >
        <Send size={18} />
      </button>
    </div>
  </div>
</footer>
      </div>      
      <UserProfile 
  isOpen={isProfileOpen} 
  onClose={() => setIsProfileOpen(false)} 
  darkMode={darkMode} 
  onSave={handleSaveProfile}
  currentUser={currentUser}
/>

<VoiceModePopup 
      isOpen={isVoiceModeActive}
      onClose={toggleVoiceMode}
      darkMode={darkMode}
      currentUser={currentUser}
      memories={memories}
      messages={messages}
      onMessageReceived={handleVoiceMessage}
      audioRef={audioRef}  // Pass the audioRef here
    />


{showHairAnalysis && (
      <HairAnalysis
        onClose={() => setShowHairAnalysis(false)}
        darkMode={darkMode}
        onImageSelect={handleHairImageSelect}
        currentUser={currentUser}
      />
    )}

{showSkincareRoutineBuilder && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 w-11/12 h-5/6 overflow-auto`}>
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold">Skincare Routine Builder</h2>
                <button 
                  onClick={() => setShowSkincareRoutineBuilder(false)}
                  className={`p-2 rounded-full ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-200'}`}
                >
                  <X size={24} />
                </button>
              </div>
              <SkincareRoutineBuilder />
            </div>
          </div>
        )}

{showInspirationOptions && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 w-80`}>
      <h2 className={`text-2xl font-bold mb-4 text-center ${darkMode ? 'text-white' : 'text-gray-800'}`}>Choose Inspiration Feature</h2>
      <div className="space-y-4">
        <button
          onClick={handleStyleHoroscopeClick}
          className="w-full py-2 px-4 bg-avacasa-500 text-white rounded-lg hover:bg-avacasa-600 transition-colors"
        >
          Style Horoscope
        </button>
        <button
          onClick={handleStyleQuizClick}
          className="w-full py-2 px-4 bg-avacasa-500 text-white rounded-lg hover:bg-avacasa-600 transition-colors"
        >
          Style Quiz
        </button>
        <button
          onClick={handleTravelPackingClick}
          className="w-full py-2 px-4 bg-avacasa-500 text-white rounded-lg hover:bg-avacasa-600 transition-colors"
        >
          Travel Packing
        </button>
      </div>
      <button
        onClick={() => {
          setShowInspirationOptions(false);
          setActiveFeature('chat');
        }}
        className={`mt-4 w-full py-2 px-4 ${
          darkMode 
            ? 'bg-gray-700 text-white hover:bg-gray-600' 
            : 'bg-gray-300 text-gray-800 hover:bg-gray-400'
        } rounded-lg transition-colors`}
      >
        Cancel
      </button>
    </div>
  </div>
)}

{showStyleHoroscope && (
  <StyleHoroscope
    onClose={() => setShowStyleHoroscope(false)}
    darkMode={darkMode}
    onSubmit={handleStyleHoroscopeSubmit}
  />
)}

{showStyleQuiz && (
  <StyleQuiz
    onClose={() => setShowStyleQuiz(false)}
    darkMode={darkMode}
    onSubmit={handleStyleQuizSubmit}
  />
)}

{showTravelPacking && (
  <TravelPacking
    onClose={() => setShowTravelPacking(false)}
    darkMode={darkMode}
    onSubmit={handleTravelPackingSubmit}
  />
)}


      {/* Discovery Options Popup */}
{showDiscoveryOptions && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 w-80`}>
      <h2 className={`text-2xl font-bold mb-4 text-center ${darkMode ? 'text-white' : 'text-gray-800'}`}>Choose Discovery Feature</h2>
      <div className="space-y-4">
        <button
          onClick={handleRateMyLookClick}
          className="w-full py-2 px-4 bg-avacasa-500 text-white rounded-lg hover:bg-avacasa-600 transition-colors"
        >
          Rate My Look
        </button>
        <button
          onClick={handleRoastMyLookClick}
          className="w-full py-2 px-4 bg-avacasa-500 text-white rounded-lg hover:bg-avacasa-600 transition-colors"
        >
          Roast My Look
        </button>
        <button
          onClick={handleStyleTransformerClick}
          className="w-full py-2 px-4 bg-avacasa-500 text-white rounded-lg hover:bg-avacasa-600 transition-colors"
        >
          Style Transformer
        </button>
        <button
          onClick={handleVersusModeClick}
          className="w-full py-2 px-4 bg-avacasa-500 text-white rounded-lg hover:bg-avacasa-600 transition-colors"
        >
          Versus Mode
        </button>
      </div>
      <button
        onClick={() => setShowDiscoveryOptions(false)}
        className={`mt-4 w-full py-2 px-4 ${
          darkMode 
            ? 'bg-gray-700 text-white hover:bg-gray-600' 
            : 'bg-gray-300 text-gray-800 hover:bg-gray-400'
        } rounded-lg transition-colors`}
      >
        Cancel
      </button>
    </div>
  </div>
)}

{showRateMyLook && (
  <RateMyLook
    onClose={() => setShowRateMyLook(false)}
    darkMode={darkMode}
    onImageSelect={(imageFile, previewUrl) => handleImageSelect(imageFile, previewUrl, 'rateMyLook')}
  />
)}

{showRoastMyLook && (
  <RoastMyLook
    onClose={() => setShowRoastMyLook(false)}
    darkMode={darkMode}
    onImageSelect={(imageFile, previewUrl) => handleImageSelect(imageFile, previewUrl, 'roastMyLook')}
  />
)}

{showStyleTransformer && (
  <StyleTransformer
    onClose={() => setShowStyleTransformer(false)}
    darkMode={darkMode}
    onImageSelect={(imageFile, previewUrl) => handleImageSelect(imageFile, previewUrl, 'styleTransformer')}
  />
)}

{showVersusMode && (
  <VersusMode
    onClose={() => setShowVersusMode(false)}
    darkMode={darkMode}
    onImagesSelect={(imageFiles, previewUrls) => handleVersusMode(imageFiles, previewUrls)}
  />
)}
      {/* Analysis Options Popup */}
{/* Analysis Options Popup */}
{showAnalysisOptions && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className={`${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg p-6 w-80`}>
      <h2 className={`text-2xl font-bold mb-4 text-center ${darkMode ? 'text-white' : 'text-gray-800'}`}>Choose Analysis Type</h2>
      <div className="space-y-4">
        <button
          onClick={handleColorAnalysisClick}
          className="w-full py-2 px-4 bg-avacasa-500 text-white rounded-lg hover:bg-avacasa-600 transition-colors"
        >
          Color Analysis
        </button>
        <button
          onClick={handleSkinAnalysisClick}
          className="w-full py-2 px-4 bg-avacasa-500 text-white rounded-lg hover:bg-avacasa-600 transition-colors"
        >
          Skin Analysis
        </button>
        <button
  onClick={handleBeautyAnalysisClick}
  className="w-full py-2 px-4 bg-avacasa-500 text-white rounded-lg hover:bg-avacasa-600 transition-colors"
>
  Beauty Analysis
</button>
<button
          onClick={handleHairAnalysisClick}
          className="w-full py-2 px-4 bg-avacasa-500 text-white rounded-lg hover:bg-avacasa-600 transition-colors"
        >
          Hair Analysis
        </button>

      </div>
      <button
        onClick={() => setShowAnalysisOptions(false)}
        className={`mt-4 w-full py-2 px-4 ${
          darkMode 
            ? 'bg-gray-700 text-white hover:bg-gray-600' 
            : 'bg-gray-300 text-gray-800 hover:bg-gray-400'
        } rounded-lg transition-colors`}
      >
        Cancel
      </button>
    </div>
  </div>
)}
{showColorAnalysis && (
  <ColorAnalysis
    onClose={() => setShowColorAnalysis(false)}
    darkMode={darkMode}
    onImageSelect={(imageFile, previewUrl) => handleImageSelect(imageFile, previewUrl, 'colorAnalysis')}
  />
)}

{showBeautyAnalysis && (
  <BeautyAnalysis
    onClose={() => setShowBeautyAnalysis(false)}
    darkMode={darkMode}
    onImageSelect={(imageFile, previewUrl, beautyInfo) => handleImageSelect(imageFile, previewUrl, 'beautyAnalysis', beautyInfo)}
    currentUser={currentUser}
    onUpdateProfile={updateUserProfile}
  />
)}

{showSkinAnalysis && (
  <SkinAnalysis
    onClose={() => setShowSkinAnalysis(false)}
    darkMode={darkMode}
    onImageSelect={handleSkinImageSelect}
    currentUser={currentUser}
    onUpdateProfile={updateUserProfile}  // Pass the function here
  />
)}


    </div>
  );
};

export default Avacasa;