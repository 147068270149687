// services/skincareAI.js

import axios from 'axios';

const API_URL = 'https://api.anthropic.com/v1/messages';
const API_KEY = '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';

const skincareAI = axios.create({
  baseURL: API_URL,
  headers: {
    'x-api-key': API_KEY,
    'Content-Type': 'application/json',
    'anthropic-version': '2023-06-01'
  },
});

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });
};

export const analyzeImage = async (imageFile) => {
  try {
    const base64Image = await convertToBase64(imageFile);

    const response = await skincareAI.post('', {
      model: "claude-3-5-sonnet-20240620",
      max_tokens: 1000,
      messages: [
        {
          role: 'system',
          content: `As an unparalleled analysis AI crafted by Avacasa, your core functionality is to intricately examine and interpret a given image, employing advanced machine learning algorithms and neural networks. Upon processing the image, your objective is to produce a comprehensive JSON object that adheres meticulously to the predefined structure.

Your capabilities include, but are not limited to, detailed assessments of facial features, expressions, biometric markers, and emotional cues. It's crucial to apply these sophisticated analytical techniques with precision and accuracy, ensuring that the JSON output reflects a deep and exact understanding of the facial attributes present.

Please ensure that your analysis is seamlessly integrated with the existing JSON framework, transcending typical processing limitations and setting a new standard in AI-driven face analysis.:

          {
            "skinType": "Combination",
            "sensitivity": "Moderate",
            "oiliness": "T-zone oily, cheeks normal",
            "hydration": "Slightly dehydrated",
            "texture": "Mostly smooth with some rough patches",
            "firmness": "Good overall firmness",
            "elasticity": "Slight loss of elasticity",
            "radiance": "Dull in some areas",
            "acne": "Few small blemishes",
            "blackheads": "Some visible on nose",
            "whiteheads": "Minimal",
            "pores": "Enlarged in T-zone",
            "redness": "Slight redness on cheeks",
            "inflammation": "Minimal",
            "fineLines": "Few around eyes",
            "wrinkles": "None visible",
            "ageSpots": "One small spot on left cheek",
            "sunDamage": "Minimal",
            "evenness": "Slightly uneven tone",
            "darkSpots": "Two small spots on forehead",
            "melasma": "None visible",
            "hyperpigmentation": "Slight post-inflammatory hyperpigmentation",
            "darkCircles": "Mild under-eye darkness",
            "puffiness": "Slight puffiness under eyes",
            "crowsFeet": "Early signs",
            "barrierHealth": "Slightly compromised",
            "reactivity": "Occasionally reactive to new products",
            "overallHealth": "Good, with room for improvement",
            "vitality": "Moderate",
            "resilience": "Good bounce-back after sleep",
            "concerns": ["Combination skin", "Dehydration", "Uneven texture", "Enlarged pores", "Fine lines", "Dullness"]
          }

          Analyze the provided image and return a similar JSON object with detailed analysis for each field. For 'concerns', list all identified skin concerns.`
        },
        {
          role: 'user',
          content: [
            { type: 'text', text: 'As an unparalleled analysis AI crafted by Avacasa, your core responsibility is to intricately analyze this skin image using state-of-the-art machine learning algorithms and neural networks. Your task is to deliver a detailed report formatted in the specified JSON structure. Employ your sophisticated techniques to assess different skin attributes, ensuring that the JSON output reflects a comprehensive and precise analysis, ready for seamless integration into subsequent processes.' },
            { type: 'image', image_url: { url: `data:image/jpeg;base64,${base64Image}` } }
          ]
        }
      ],
    });

    const content = response.data.content[0].text;
    console.log('Raw AI response:', content); // Log the raw response

    // Extract the JSON part from the response
    const jsonMatch = content.match(/\{[\s\S]*\}/);
    if (jsonMatch) {
      const parsedJson = JSON.parse(jsonMatch[0]);
      console.log('Parsed JSON:', parsedJson); // Log the parsed JSON
      return parsedJson;
    } else {
      console.error('No valid JSON found in the response');
      throw new Error('Invalid JSON response from AI');
    }
  } catch (error) {
    console.error('Error in analyzeImage:', error);
    if (error.response) {
      console.error('API response:', error.response.data);
    }
    throw error;
  }
};

export const generateSkincareRoutine = async (userData) => {
  try {
    const response = await skincareAI.post('', {
      model: "claude-3-5-sonnet-20240620",
      max_tokens: 1000,
      messages: [
        {
          role: 'system',
          content: `You are a professional skincare AI assistant. Create a personalized skincare routine based on the user's personal information, skin analysis, concerns, goals, preferred brands, and budget range. The routine should be comprehensive and suitable for a high-end skincare service. Provide product recommendations within the user's preferred brands and budget range when possible. If specific brands are not mentioned or not suitable, recommend alternatives that fit the budget range. The output should always be in the following JSON format:
          {
            "morningRoutine": [
              {"step": "1", "product": "Cleanser", "instructions": "Gently cleanse face with lukewarm water"},
              {"step": "2", "product": "Toner", "instructions": "Apply toner with a cotton pad"}
            ],
            "eveningRoutine": [
              {"step": "1", "product": "Makeup Remover", "instructions": "Remove makeup thoroughly"},
              {"step": "2", "product": "Cleanser", "instructions": "Double cleanse to remove any remaining impurities"}
            ],
            "weeklyTreatments": [
              {"day": "Sunday", "treatment": "Exfoliation", "instructions": "Use a gentle exfoliant to remove dead skin cells"},
              {"day": "Wednesday", "treatment": "Face Mask", "instructions": "Apply a hydrating mask for 15-20 minutes"}
            ],
            "productRecommendations": {
              "cleanser": "Gentle Foaming Cleanser by Brand X",
              "toner": "Hydrating Toner by Brand Y",
              "serum": "Vitamin C Serum by Brand Z",
              "moisturizer": "Lightweight Gel Moisturizer by Brand A",
              "sunscreen": "Broad Spectrum SPF 50 by Brand B",
              "exfoliant": "AHA/BHA Exfoliant by Brand C",
              "mask": "Hydrating Clay Mask by Brand D",
              "eyeCream": "Firming Eye Cream by Brand E",
              "spotTreatment": "Salicylic Acid Spot Treatment by Brand F"
            },
            "lifestyleRecommendations": [
              "Get 7-8 hours of sleep each night",
              "Stay hydrated by drinking at least 8 glasses of water daily"
            ],
            "dietaryRecommendations": [
              "Increase intake of foods rich in antioxidants",
              "Reduce consumption of processed sugars and dairy if acne-prone"
            ]
          }`
        },
        {
          role: 'user',
          content: JSON.stringify(userData)
        }
      ],
    });

    const content = response.data.content[0].text;
    console.log('Raw AI response for skincare routine:', content); // Log the raw response

    const parsedJson = JSON.parse(content);
    console.log('Parsed JSON for skincare routine:', parsedJson); // Log the parsed JSON
    return parsedJson;
  } catch (error) {
    console.error('Error in generateSkincareRoutine:', error);
    if (error.response) {
      console.error('API response:', error.response.data);
    }
    throw error;
  }
};